import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TemplateService {
  baseUrl: string;
  constructor(public http: HttpClient) {
    this.baseUrl = environment.apiURL;
  }
  getCreativeTemplates(layoutId, themeId) {
    return this.http.get(
      this.baseUrl + '/blueprint?layoutId=' + layoutId + '&themeId=' + themeId
    );
  }
}
