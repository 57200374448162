import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CreativeService {
  baseUrl: string;
  constructor(public http: HttpClient) {
    this.baseUrl = environment.apiURL;
  }

  getLayouts(params): any {
    return this.http.get(this.baseUrl + '/brand/layout/' + params);
  }
  getThemes(params): any {
    return this.http.get(this.baseUrl + '/brand/theme/' + params);
  }
  getBrands() {
    return this.http.get(this.baseUrl + '/brand');
  }
}
