import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';

import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { CreativeComponent, TemplateComponent } from './Components/components';
import { CreativeService, TemplateService } from './Services/services';
import { AppRoutingModule } from './app-routing.module';
import {
  CUSTOM_FORMLY_CONFIG,
  EpsilonAxisFormModule
} from 'epsilon-we-axis-form';

@NgModule({
  declarations: [
    AppComponent,
    CreativeComponent,
    TemplateComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    FormlyModule.forRoot(CUSTOM_FORMLY_CONFIG),
    EpsilonAxisFormModule
  ],
  providers: [CreativeService, TemplateService],
  bootstrap: [AppComponent]
})
export class AppModule {}
