export const environment = {
  production: false,
  envName: 'staging',
  apiURL: 'https://staging.axis-api.epsilonrms.com/DYNAMICDEALERADAPI/api/dynamicDealerAd',
  SSOContactUrl:
    'https://staging.consumerconnection.dealerconnection.com/app/Home/Contact',
  nonSSOContactUrl: 'https://staging.ng.ford.aspenrms.com/app/Home/Contact',
  SSOResourceUrl:
    'https://staging.consumerconnection.dealerconnection.com/app/Resources',
  nonSSOResourceUrl: 'https://staging.ng.ford.aspenrms.com/app/Resources'
};
