import { IResponse } from './../../models/IResponse';
import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CreativeService } from '../../Services/services';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-creative',
  templateUrl: './creative.component.html',
  styleUrls: ['./creative.component.css']
})
export class CreativeComponent implements OnInit {
  brand;
  isFormReady = false;
  layout = [];
  theme = [];
  template;
  qryStringBrand = 'Ford';
  ssoNo;
  SSOContactUrl;
  nonSSOContactUrl;
  SSOResourceUrl;
  nonSSOResourceUrl;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public creativeService: CreativeService,
    private http: HttpClient
  ) {}

  form = new FormGroup({});

  model: any = {};

  options = {
    formState: {
      component: this,
      isFord: true,
      fordBtn: true,
      qlBtn: true
    }
  };

  headerFields: FormlyFieldConfig[];

  headerButtonFields: FormlyFieldConfig[];

  layoutFields: FormlyFieldConfig[];

  themeFields: FormlyFieldConfig[];

  otherButtonFields: FormlyFieldConfig[];

  enhancementFields: FormlyFieldConfig[];

  footerFields: FormlyFieldConfig[];

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.qryStringBrand = params['brand'];
      this.ssoNo = +params['sso'];
      if (this.ssoNo === 1) {
        this.model.resourceUrl = environment.SSOResourceUrl;
        this.model.contactUrl = environment.SSOContactUrl;
      } else {
        this.model.contactUrl = environment.nonSSOContactUrl;
        this.model.resourceUrl = environment.nonSSOResourceUrl;
      }
    });

    // this.getBrands();
    this.getJsonDetail();
  }

  getJsonDetail(): any {
    // get users from api
    return this.http
      .get('../../../assets/formly-fields/creative.json')
      .subscribe((response: IResponse) => {
        this.template = response.data;
        this.getFormlyFields();

        this.getBrands();
      });
  }

  /**
   * Get all brands
   */
  getBrands() {
    this.creativeService.getBrands().subscribe(
      (data) => {
        this.brand = data;
        // this.getLayouts(this.brand[0].id);
        // this.getThemes(this.brand[0].id);

        this.showHideBrands();
      },
      (err) => {}
    );
  }

  showHideBrands() {
    if (!this.qryStringBrand) {
      this.qryStringBrand = 'f';
    }

    const queryStringArr = this.qryStringBrand.split(',');

    if (queryStringArr.length === 3) {
      this.clickFord(this);
    } else if (queryStringArr.length === 2) {
      if (queryStringArr.indexOf('f') !== -1) {
        if (queryStringArr.indexOf('ql') !== -1) {
          this.options.formState.qlBtn = true;
        } else {
          this.options.formState.qlBtn = false;
        }

        this.options.formState.fordBtn = true;
        this.clickFord(this);
      } else {
        this.options.formState.qlBtn = true;
        this.options.formState.fordBtn = false;
        this.clickQuickLane(this);
      }
    } else if (queryStringArr.length === 1) {
      if (queryStringArr.indexOf('f') !== -1) {
        this.options.formState.qlBtn = false;
        this.options.formState.fordBtn = true;
        this.clickFord(this);
      } else {
        this.options.formState.qlBtn = true;
        this.options.formState.fordBtn = false;
        this.clickQuickLane(this);
      }
    } else {
      this.qryStringBrand = 'f';
      this.clickFord(this);
    }
  }

  getLayouts(brandId) {
    this.creativeService.getLayouts(brandId).subscribe(
      (data) => {
        this.layout = data;
        this.layout.forEach((item) => {
          item.file_name = 'assets/images/' + item.file_name;
          item.objid = item.id;
          item.key = item.id;
        });
        this.layout[0].isChecked = true;
        this.model.layout = this.layout[0].id;

        this.template[0].layoutFields[1].templateOptions.options = this.layout;
        this.layoutFields = this.template[0].layoutFields;
        this.isFormReady = true;
        // this.getFormlyFields();
      },
      (err) => {}
    );
  }

  getThemes(brandId) {
    this.creativeService.getThemes(brandId).subscribe(
      (data) => {
        this.theme = data;
        this.theme.forEach((item) => {
          item.file_name = 'assets/images/' + item.file_name;
          item.objid = item.id;
          item.key = item.id;
        });
        this.theme[0].isChecked = true;
        this.model.theme = this.theme[0].id;

        this.template[0].themeFields[0].templateOptions.options = this.theme;
        this.themeFields = this.template[0].themeFields;
        this.isFormReady = true;
        // this.getFormlyFields();
      },
      (err) => {}
    );
  }

  clickFord(vm) {
    vm.options.formState.isFord = true;
    const fordbtn = <HTMLScriptElement>(
      document.getElementsByClassName('fordbtn')[0]
    );
    fordbtn.style.backgroundColor = '#444444';
    const quicklanebtn = <HTMLScriptElement>(
      document.getElementsByClassName('quicklanebtn')[0]
    );
    quicklanebtn.style.backgroundColor = '#6f6f6f';
    const id = vm.brand.filter((d) => d.name === 'Ford')[0].id;
    vm.getLayouts(id);
    vm.getThemes(id);
  }

  clickQuickLane(vm) {
    vm.options.formState.isFord = false;
    const fordbtn = <HTMLScriptElement>(
      document.getElementsByClassName('fordbtn')[0]
    );
    fordbtn.style.backgroundColor = '#6f6f6f';
    const quicklanebtn = <HTMLScriptElement>(
      document.getElementsByClassName('quicklanebtn')[0]
    );
    quicklanebtn.style.backgroundColor = '#444444';
    const id = vm.brand.filter((d) => d.name === 'QuickLane')[0].id;
    vm.getLayouts(id);
    vm.getThemes(id);
  }

  getFormlyFields() {
    const template = this.template;
    this.headerFields = template[0].headerFields;
    this.headerButtonFields = template[0].headerButtonFields;
    // this.layoutFields = template[0].layoutFields;
    // this.themeFields = template[0].themeFields;
    this.otherButtonFields = template[0].otherButtonFields;
    this.enhancementFields = template[0].enhancementFields;
    this.footerFields = template[0].footerFields;
  }

  next(vm) {
    vm.router.navigate([
      '/dynamicDealerAd/template',
      vm.model.layout,
      vm.model.theme,
      vm.qryStringBrand
    ]);
  }

  submit(model) {}
  // getFormlyFields(){
  //   this.headerFields = [
  //     {
  //       template: '<h1>All-New ConsumerConnection Creative Enhancement</h1>'
  //     },
  //     {
  //       template: `<p>ConsumerConnection has launched all-new creative.
  //         See below to learn how you can customize your communications to make them more personalized for your dealership -
  //         and especially for your customers.</p>`
  //     }
  //   ];
  //   this.headerButtonFields = [
  //     {
  //       key: 'brandName',
  //       type: 'ep-input-button',
  //       templateOptions: {
  //         style: 'callout-btn dark-gray btn-ford',
  //         text: 'Ford',
  //         onClick: this.changeBrand
  //       }
  //     },
  //     {
  //       key: 'brandName',
  //       type: 'ep-input-button',
  //       templateOptions: {
  //         style: 'callout-btn light-gray btn-ql',
  //         text: 'Quick Lane',
  //         onClick: this.changeBrand
  //       }
  //     }
  //   ];

  //   this.layoutFields = [
  //     {
  //       template: `<p class="layout-heading">Please select a layout</p>`
  //     },
  //     {
  //       key: 'layout',
  //       type: 'ep2-radio',
  //       className: 'radio-change',
  //       templateOptions: {
  //         label: 'Default',
  //         style: 'radio-change',
  //         divStyle: 'layout-selection-left',
  //         numberingStyle: 'numbering',
  //         imgStyle: 'flayout',
  //         textStyle: 'default-layout',
  //         text: 'Default',
  //         options: this.layout
  //       }
  //     }
  //   ];

  //   this.themeFields = [
  //     {
  //       key: 'theme',
  //       type: 'ep2-radio',
  //       className: 'radio-change',
  //       templateOptions: {
  //         label: 'Default',
  //         style: 'radio-change',
  //         divStyle: 'FordColorTheme1',
  //         numberingStyle: 'numbering theme',
  //         imgStyle: 'ftheme',
  //         textStyle: 'default-layout',
  //         text: 'Default',
  //         options: this.theme
  //       }
  //     }
  //   ];

  //   this.otherButtonFields = [
  //     {
  //       key: 'otherEnhancements',
  //       type: 'ep-input-button',
  //       templateOptions: {
  //         style: 'callout-btn light-gray enhancements-btn btn-next ford-enhancement-btn',
  //         text: 'See Other Enhancements',
  //         disabled: false
  //       }
  //     },
  //     {
  //       key: 'submit',
  //       type: 'ep-input-button',
  //       templateOptions: {
  //         style: 'callout-btn light-gray enhancements-btn btn-next',
  //         text: 'Next',
  //         disabled: false,
  //         onClick: this.submit
  //       }
  //     }
  //   ];

  //   this.enhancementFields = [
  //     {
  //       template: `<div class="customize-text-container ford-custom-container" id="ford-other-enhancements">
  //           <p>Please contact your ConsumerConnection Product Specialist or Program Representative as soon as possible to guarantee
  //             your
  //             <br> color and layout choices. Further customize your communications by selecting and providing these additional items:</p>
  //         </div>`
  //     },
  //     {
  //       template: `<div class="customize-brakeimage-container">
  //           <div class="customize-brakeimage-left">
  //             <img src="assets/images/Brake_Image_whole.png" alt="Brake, Tire, Battery" />
  //           </div>
  //           <div class="customize-brakeimage-right">
  //             <p class="sub-customize-text">New for Ford and Quick Lane</p>
  //             <p>Select from three different images for your Brake, Tire and Battery on your communications where applicable.</p>
  //           </div>
  //         </div>`
  //     },
  //     {
  //       template: `<div class="customize-brakeimage-container">
  //           <div class="customize-brakeimage-left customize-brakeimage-left2">
  //             <img src="assets/images/Hero_image_placeholder.jpg" alt="Hero Image Placeholder" />
  //           </div>
  //           <div class="customize-brakeimage-right">
  //             <p class="sub-customize-text">Images</p>
  //             <p> Select from a variety of images for your communications. Pick from a selection of static images or select seasonal
  //               images (where applicable) and those images will be updated automatically throughout the year.</p>
  //           </div>
  //         </div>`
  //     },
  //     {
  //       template: `<div class="customize-brakeimage-container">
  //           <div class="customize-brakeimage-left">
  //             <div class="image-upload">
  //               <img src="assets/images/Dealer_upload.png" alt="Dealer Logo" />
  //             </div>
  //           </div>
  //           <div class="customize-brakeimage-right">
  //             <p class="sub-customize-text">New for Ford and Quick Lane</p>
  //             <p>Provide your own Service Manager image to add to service communications (if applicable).</p>
  //           </div>
  //         </div>`
  //     },
  //     {
  //       template: `<div class="customize-brakeimage-container">
  //           <div class="customize-brakeimage-left">
  //             <div class="image-upload">
  //               <img src="assets/images/ford_default_logo.png" alt="Ford Logo" />
  //               <br>
  //               <span class="default-layout-heroimage">Default</span>
  //             </div>
  //             <div class="default-logo-container">
  //               <img src="assets/images/dealer_provided_logo.png" alt="Dealer Logo" />
  //             </div>
  //           </div>
  //           <div class="customize-brakeimage-right">
  //             <p class="sub-customize-text">Logos</p>
  //             <p>If you’re not already on file with ConsumerConnection please provide a custom dealer logo. See
  //               <a class="resources-link" href="https://consumerconnection.dealerconnection.com/app/Resources"
  //                  target="_blank">Resources</a>
  //                section for logo specs.
  //             </p>
  //           </div>
  //         </div>`
  //     },
  //     {
  //       template: `<div class="customize-brakeimage-container">
  //           <div class="customize-brakeimage-left">
  //             <div class="image-upload">
  //               <img src="assets/images/ford_default_logo.png" alt="Ford Logo" />
  //               <br>
  //               <span class="default-layout-heroimage">Default</span>
  //             </div>
  //             <div class="default-logo-container">
  //               <img src="assets/images/dealer_provided_logo.png" alt="Dealer Logo" />
  //             </div>
  //           </div>
  //           <div class="customize-brakeimage-right">
  //             <p class="sub-customize-text">Logos</p>
  //             <p>If you’re not already on file with ConsumerConnection please provide a custom dealer logo. See
  //               <a class="resources-link" href="https://consumerconnection.dealerconnection.com/app/Resources"
  //                 target="_blank">Resources</a>
  //                section for logo specs.
  //             </p>
  //           </div>
  //         </div>`
  //     },
  //     {
  //       template: `<div class="customize-brakeimage-container">
  //           <div class="customize-brakeimage-left">
  //             <div class="default-logo-container2">
  //               <img src="assets/images/Dealer_outerview_image.png" alt="Dealer Image" />
  //               <span class="default-layout-heroimage">Default</span>
  //             </div>
  //           </div>
  //           <div class="customize-brakeimage-right">
  //             <p class="sub-customize-text">Gold Dealers</p>
  //             <p> Provide your custom dealership image or the default image to the left will be used. See
  //               <a class="resources-link" href="https://consumerconnection.dealerconnection.com/app/Resources"
  //                    target="_blank">Resources</a>
  //                section for dealership image specs.
  //             </p>
  //           </div>
  //         </div>`
  //     },
  //     {
  //       template: `<div class="customize-brakeimage-container">
  //           <div class="customize-brakeimage-left">
  //             <div class="default-logo-container3">
  //               <img src="assets/images/Service_text.png" alt="Text Placeholder" />
  //               <br>
  //               <span class="default-layout-heroimage">Default</span>
  //             </div>
  //           </div>
  //           <div class="customize-brakeimage-right">
  //             <p class="sub-customize-text">Gold Dealers</p>
  //             <p>Customize your copy for the following communications or use the default copy example to the left</p>
  //             <ul class="listItems">
  //               <li>Service Reminder Lost</li>
  //               <li>Intro to Service</li>
  //               <li>Service Reminder Loyal</li>
  //               <li>Past Due Maintenance</li>
  //               <li>Decline Services</li>
  //               <li>Service Appointment Reminder</li>
  //               <li>Purchase Thank you</li>
  //             </ul>
  //           </div>
  //         </div>`
  //     }
  //   ];

  //   this.footerFields = [
  //     {
  //       template: `<p class="footer-text">
  //           Click below to contact us today and begin customizing the brand-new enhanced creative.
  //       </p>`
  //     },
  //     {
  //       template: `<a class="contactus-link" target="_blank" href="https://consumerconnection.dealerconnection.com/app/Home/Contact">
  //       Contact Us</a>`
  //     },
  //     {
  //       template: `<p class="footer-text enrollment-text">
  //           If you would like to speed up the process, please download the enrollment form below
  //           <br> and scan or fax it to the number provided at the bottom of the form.
  //       </p>`
  //     },
  //     {
  //       template: `<a class="contactus-link creative-enrollment" target="_blank" href="#">
  //           <img class="pdf-icon" src="assets/images/pdf_icon.png" alt="PDF Icon" />
  //           <span>Enhanced Creative Enrollment Form</span>
  //       </a>`
  //     }
  //   ];
  // }
}
