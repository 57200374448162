import { Component, OnInit, Sanitizer } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TemplateService } from '../../Services/services';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-final-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit {
  params;
  isFormReady = false;
  layoutId: number;
  themeId: number;
  qryStringBrand: string;
  template;
  template1;
  creativeTemplate;
  constructor(
    public http: HttpClient,
    public templateService: TemplateService,
    private route: ActivatedRoute,
    public router: Router
  ) {
    this.route.params.subscribe((params: Params) => {
      this.layoutId = params.layoutId;
      this.themeId = params.themeId;
      this.qryStringBrand = params.brand;
    });
  }

  form = new FormGroup({});

  options = {
    formState: {
      component: this,
      isTemplate1Available: true
    }
  };

  templateModel: any = {};

  navButtonFields: FormlyFieldConfig[];

  downloadPDFField: FormlyFieldConfig[];

  ngOnInit() {
    this.templateService
      .getCreativeTemplates(this.layoutId, this.themeId)
      .subscribe(
        (creativeTemplate) => {
          this.creativeTemplate = creativeTemplate;
          this.templateModel.creativeTemplate = creativeTemplate;
          if (!this.templateModel.creativeTemplate[1]) {
            this.options.formState.isTemplate1Available = false;
          }
          this.getJsonDetail();
        },
        (err) => {}
      );
  }

  getJsonDetail(): any {
    return this.http
      .get('../../../assets/formly-fields/template.json')
      .subscribe((response) => {
        this.template1 = response;
        this.getFormlyFields(this.template1.data);
        this.isFormReady = true;
      });
  }

  templates() {
    this.templateService
      .getCreativeTemplates(this.layoutId, this.themeId)
      .subscribe((creativeTemplate) => {
        this.template = creativeTemplate;
      });
  }

  downloadDMPDF(vm) {
    window.open('/assets/pdf/' + vm.creativeTemplate[0].file_name);
  }

  downloadEMPDF(vm) {
    window.open('/assets/pdf/' + vm.creativeTemplate[1].file_name);
  }

  getFormlyFields(template) {
    this.navButtonFields = template[0].navButtonFields;
    this.downloadPDFField = template[0].downloadPDFField;
  }

  backToHome(vm) {
    vm.router.navigate(['/dynamicDealerAd/creative'], {
      queryParams: { brand: vm.qryStringBrand }
    });
  }
}
