import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreativeComponent, TemplateComponent } from './Components/components';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/dynamicDealerAd',
    pathMatch: 'full'
  },
  {
    path: 'dynamicDealerAd',
    component: CreativeComponent
  },
  {
    path: 'dynamicDealerAd/creative',
    component: CreativeComponent
  },
  {
    path: 'dynamicDealerAd/template/:layoutId/:themeId/:brand',
    component: TemplateComponent
  },
  // { path: '**', component: PageNotFoundComponent }
  { path: '**', component: CreativeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
